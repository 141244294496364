

// text: 'var(--gray-900)',
// #718096
export const lightTheme = {
    colorz: '#898687',
    body: 'white',
    previewCard: 'var(--ral7043)',
    previewCardBorder: 'white',
    text: '#898687',
    tag: '#d6ead7',
    tagtext: '#898687',
    toggleBorder: 'var(--gray-800)',
    background: '#FCFCFC',
    hover: 'var(--blue-900)',
    shadow: 'rgba(0, 0, 0, 0.25)',
    svg: 'var(--orange)',
    mobile: '1000px',
    footer: 'white',
}

