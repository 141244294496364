import React from 'react'
import styled from 'styled-components'
import { ReactComponent as Instagram } from '../images/instagram.svg'


const FooterGroup = styled.div`
  position: relative;
  background: ${({ theme }) => theme.footer};
  padding: 50px 0;
  display: grid;
  grid-gap: 20px;
`

const Text = styled.p`
  font-size: 24px;
  color: ${({ theme }) => theme.text};
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  a {
    color: var(--blue-500);
    font-size: 1rem;
    font-weight: 500;
    margin: 5px 0;
    transition: 1s;
  }

  a:hover {
    color: ${({ theme }) => theme.text};
  }
`
const SocialIcons = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 10px;
    font-size: 24px;
    color: white;
    border: none;
    padding: 16px 60px;
    font-weight: 600;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    z-index: 2;
    svg{
      height:1.8rem;
      width:1.8rem;
    }

    
`

const Copyright = styled.div`
  color: var(--RR);
  max-width: 500px;
  margin: 0 auto;
  padding: 0 20px;
  font-size: 15px;
`

const Footer = () => (
  <FooterGroup>
    <Text>
      Scrie-ne pentru orice intrebare: {' '} <br />
      office@acera.ro
    </Text>
    <SocialIcons>

      <a
        href="https://www.instagram.com/aceraconstruct/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Instagram />
      </a>

    </SocialIcons>

    <Copyright>
      Made by RR{' '}©2020
    </Copyright>
  </FooterGroup>
)

export default Footer
