import React from 'react';
import { GlobalStyles } from '../utils/global'
import { ThemeProvider } from 'styled-components'
import { lightTheme } from '../utils/theme'
import Header from './Header';
import Footer from './Footer'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'



const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title,
                    author,
                    description,
                }
            }
        }
    `)

    return (
        <ThemeProvider theme={lightTheme}>
            <React.Fragment>
                <Helmet title={`${data.site.siteMetadata.title} | ${data.site.siteMetadata.description}`} />
                <GlobalStyles />
                <Header />
                <main>{children}</main>
                <Footer />
            </React.Fragment>
        </ThemeProvider>
    )
}

export default Layout;