import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { ReactComponent as Logo } from '../images/acera_verde.svg'
import { useOnClickOutside } from '../utils/clickOutsideHook'
import Burger from './Burger'

const StyledHeader = styled.div`
  width: 100vw;
  max-width: 100%;
  /* @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 8ch;
  } */
`
const BurgerMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.body};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 6;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  display: ${({ open }) => (open ? 'flex' : 'none')};
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    font-size: 3rem;
  }

  
  a {
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    padding: 2rem 0;
    letter-spacing: 0.5rem;
    color: ${({ theme }) => theme.text};
    text-decoration: none;
    transition: color 0.3s linear;
    
    @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: .4rem;
    font-size: .9rem;
  }

    &:hover {
      color: ${({ theme }) => theme.hover};
    }
  }
`

const StyledLinks = styled.div`
  position: relative;
  width: 80ch;
  max-width: 95%;
  margin: 2rem auto;
  margin-top:2rem;
  margin-bottom:3rem;
  display: flex;
  justify-content: space-between;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: none;
  }
  a {
    margin-left: 0rem;
    text-transform: uppercase;
  }
  
  svg {
    margin-top: 4px;
    fill: var(--gray-900);
    width: 20px;
    height: 20px;
  }
`
const LinkLogo = styled(Link)`
  display: flex;
  justify-content: center;
  margin-top: 1.8rem;
  margin-bottom: -10px;
  svg {
      max-width: 30%;
    }

  /*mobile*/
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: -1rem;
    svg {
      margin-top: 0rem;
      max-width: 40%;
    }
  }
`
// const DesignBtn = styled.button`
//   font-size: .9rem;
//   border: none;
//   outline: none;
//   background-color: inherit;
//   color:var(--gray-900);
//   font-family: inherit;
//   text-transform: uppercase;
//     text-align: center;
//     padding: .4rem 0;
//     letter-spacing: 0.5rem;
//     color: ${({ theme }) => theme.text};
//     text-decoration: none;
//     transition: color 0.3s linear;
//     svg {
//     margin-top: 4px;
//     fill: var(--gray-900);
//     width: 20px;
//     height: 20px;
//   }

//     /* &:hover {
//       color: ${({ theme }) => theme.hover};
//     } */
// `
// const DesignSubmenu = styled(BurgerMenu)`
//   transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(100%)')};
//   display: ${({ show }) => (show ? 'flex' : 'none')};
// `

const Header = () => {
  const [open, setOpen] = React.useState(false)
  // const [show, setShow] = React.useState(false)

  const node = React.useRef()
  useOnClickOutside(node, () => setOpen(false))

  return (
    <StyledHeader>
      <Burger open={open} setOpen={setOpen} />
      <BurgerMenu open={open}>
        <Link id="Home" to="/">
          Acasa
        </Link>
        <Link id="about" to="/about">
          Compania
        </Link>
        <Link id="servicii" to="/servicii">
          Servicii
        </Link>
        <Link id="contact" to="/contact">
          Contact
        </Link>
      </BurgerMenu>
      <LinkLogo to="/">
        <Logo />
      </LinkLogo>
      <StyledLinks>
        <Link id="Home" to="/">
          Acasa
        </Link>
        <Link id="about" to="/about">
          Compania
        </Link>
        <Link id="servicii" to="/servicii">
          Servicii
        </Link>
        <Link id="contact" to="/contact">
          Contact
        </Link>
      </StyledLinks>
    </StyledHeader>
  )
}
// Header.propTypes = {
//   open: bool.isRequired,
// }
export default Header;
